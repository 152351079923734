import React, { useState } from "react";
import "./styles.scss";
import validateForm from "../../_utils/ValidateForm";
import useForm from "../../_utils/UseForm";

const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const UserForm = ({ btnText, isAccountForm, values }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const {
        handleChange,
        handleSubmit,
        form,
        errors,
        updateForm,
    } = useForm(validateForm, isAccountForm, values);

    const {
        email,
        emailRepeat,
        fullName,
        favouriteCuisine,
        postcode,
        firstTasteExperience,
        dobYear,
        dobMonth,
        dobDay,
        newsletterSignup
    } = isAccountForm ? updateForm : form;

    const submitForm = (e) => {
        handleSubmit(e).then(response => {
            if(!!response && response.message_message) {
                setErrorMessage(response.message_message);
            } 
            
            if(!!response && response.success_message){
                setSuccessMessage(response.success_message);
            }
        }, (err) => {
            if(!!err && err.message_message) {
                setErrorMessage(err.message_message);
            } else {
                setErrorMessage('We cannot process your request now. Please try again later.');
            }
        })
    }

    return (
        <form className="registration-form" onSubmit={(e) => submitForm(e)}>
            <div className="mb-3">
                <label htmlFor="f-name" className="form-label">
                    Name <span className="text-danger">*</span>
                </label>
                <input
                    type="text"
                    name="fullName"
                    id="f-fullName"
                    className="form-control"
                    value={fullName}
                    onChange={handleChange}
                    required={true}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="f-email" className="form-label">
                    Email <span className="text-danger">*</span>
                </label>

                <input
                    type="email"
                    name="email"
                    id="f-email"
                    className="form-control"
                    value={email}
                    readOnly={isAccountForm}
                    onChange={handleChange}
                    required={!isAccountForm}
                />
            </div>

            {!isAccountForm ? (
                <div className="mb-3">
                    <label htmlFor="f-emailRepeat" className="form-label">
                        Re-Enter Email <span className="text-danger">*</span>
                    </label>

                    <input
                        type="email"
                        name="emailRepeat"
                        id="f-emailRepeat"
                        className="form-control"
                        value={emailRepeat}
                        onChange={handleChange}
                        required={true}
                    />
                </div>
            ) : null}

            <div className="form-group mb-3">
                <label htmlFor="f-dobYear" className="form-label">
                    Date of birth <span className="text-danger">*</span>
                </label>

                <div className="dob">
                    <div className="form-item">
                        <select className="form-select"
                                required
                                name="dobMonth"
                                value={dobMonth}
                                id="user-month"
                                onChange={handleChange}>
                            <option value="">Month</option>
                            {[...Array(12).keys()].map(x => x + 1).map((item, index) => (
                                <option value={item} key={index}>
                                    {!!item && !!monthLabels[item - 1] ? monthLabels[item - 1] : 'Month'}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-item">
                        <select className="form-select"
                                required
                                name="dobDay"
                                value={dobDay}
                                id="user-day"
                                onChange={handleChange}>
                            <option value="">Day</option>
                            {[...Array(31).keys()].map(x => x + 1).map((item, index) => (
                                <option value={item} key={index}>
                                    {!!item ? item : 'Day'}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-item">
                        <select
                            className="form-select"
                            onChange={handleChange}
                            name="dobYear"
                            id="f-dobYear"
                            value={dobYear}
                            required={true}
                        >
                            <option value="">Year</option>
                            {[...Array(100)].map((x, i) =>
                                <option
                                    key={`dob-y-${i}`}
                                    value={2021-i}
                                >
                                    {2021-i}
                                </option>,
                            )}
                        </select>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="f-name" className="form-label">
                    ZIP CODE <span className="text-danger">*</span>
                </label>

                <input
                    type="text"
                    name="postcode"
                    id="f-postcode"
                    className="form-control"
                    value={postcode}
                    onChange={handleChange}
                    required={true}
                />
            </div>

            <div className="mb-3">
                <label htmlFor="f-name" className="form-label">
                    Is this your first visit to Mubadala Silicon Valley Classic?
                </label>

                <select
                    className="form-select"
                    onChange={handleChange}
                    name="firstTasteExperience"
                    id="f-firstTasteExperience"
                    value={firstTasteExperience}
                >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="f-favouriteCuisine" className="form-label">
                    Will you consider returning next year?
                </label>

                <select
                    className="form-select"
                    onChange={handleChange}
                    name="favouriteCuisine"
                    id="f-favouriteCuisine"
                    value={favouriteCuisine}
                >
                    <option value=""></option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            {!isAccountForm && (
                <>
                    <hr />

                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="f-terms" required={true}/>
                        <label className="form-check-label" htmlFor="f-terms">
                            I agree to <a href="/terms/" style={{ "textDecoration": "underline" }} target="_blank">Terms &
                            Conditions</a>
                            <span className="text-danger">*</span>
                        </label>
                    </div>
                </>
            )}

            {Object.keys(errors).map((errorName, index) => {
                if (errors[errorName] !== null) {
                    return (
                        <div className="form-text text-danger error-message" key={index}>
                            {errors[errorName]}
                        </div>
                    );
                }
                return null;
            })}

            <hr />

            {!!errorMessage && (
                <p className="error-message">{errorMessage}</p>
            )}

            {!!successMessage && (
                <p className="success-message">{successMessage}</p>
            )}

            <button type="submit" className="btn btn-primary btn-form">
                {btnText}
            </button>
        </form>
    );
};

export default UserForm;
