import { useEffect, useState } from "react";
import Qr from "./Qr";
import Database from "./Database";
import { goto } from "./Misc";
import moment from "moment";

const useForm = (validateForm, isAccountForm, values) => {
    const [form, setForm] = useState({
        email: "",
        emailRepeat: "",
        fullName: "",
        favouriteCuisine: "",
        postcode: "",
        firstTasteExperience: "",
        // favouriteFood: "",
        dobYear: "",
        dobMonth: "",
        dobDay: "",
        gender: "",
        newsletterSignup: ""
    });

    const [updateForm, setUpdateForm] = useState({
        email: "",
        fullName: "",
        favouriteCuisine: "",
        postcode: "",
        firstTasteExperience: "",
        dobYear: "",
        dobMonth: "",
        dobDay: "",
        gender: "",
        userId: null,
        newsletterSignup: ""
    });

    const [errors, setErrors] = useState({
        email: null,
        emailRepeat: null,
        fullName: null,
        bod: null,
    });

    useEffect(() => {
        if (values) {
            setForm(values);
            setUpdateForm(values);
        }
    }, [values]);

    const handleChange = ({ target }) => {
        const name = target.name;
        const value = target.type === "checkbox" ? target.checked : target.value;

        isAccountForm
            ? setUpdateForm({
                ...updateForm,
            [name]: value,
    })
    : setForm({
                ...form,
            [name]: value,
    });
    };

    let getAge = () => {
        let birthDateString = form.dobYear + '-' + form.dobMonth + '-' + form.dobDay;
        let today = new Date();
        let birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const handleRegistrationSubmit = async e => {
        e.preventDefault();

        const formToValidate = form;
        const validateErrors = validateForm(formToValidate, isAccountForm);
        const { email, emailRepeat, fullName, dob } = validateErrors;

        if (email === null && emailRepeat === null && fullName === null && dob === null) {
            try {
                const dobIn = moment(`${formToValidate.dobYear}-${formToValidate.dobMonth}-${formToValidate.dobDay}`, "YYYY-MM-DD");
                const { clickId, campaignId, code, variant } = Qr.getStoredParams();

                const requestData = {
                    click_id: clickId,
                    anonymise: getAge() < 18,
                    campaign_id: campaignId,
                    code,
                    variant,
                    consumer_email: formToValidate.email,
                    consumer_name: formToValidate.fullName,
                    preference_next_year: formToValidate.favouriteCuisine,
                    preference_postcode: formToValidate.postcode,
                    preference_first_experience: formToValidate.firstTasteExperience,
                    consumer_dob: dobIn.isValid() ? dobIn.format("YYYY-MM-DD") : null,
                    consumer_marketing_consent: !!formToValidate.newsletterSignup,
                    language: "en"
                };

                const request = await fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/register`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                }).then(async response => {
                    const responseObject = await response.json();
                    const status = response.status;

                    if (!responseObject) {
                        throw new Error("failed while trying to get response");
                    }

                    if (status === 200 || status === 201) {
                        Database.removeQrParams();

                        if (responseObject.user_token) {
                            Database.setUserToken(responseObject.user_token);
                        } else {
                            Database.removeUserToken();
                        }

                        if (responseObject.top_message) {
                            Database.setRewardsMessage(responseObject.top_message);
                        } else {
                            Database.removeRewardsMessage();
                        }

                        if (responseObject.rewards) {
                            Database.setRewardsList(responseObject.rewards);
                        } else {
                            Database.removeRewardsList();
                        }

                        goto("/qr-rewards/");
                    } else {
                        return responseObject;
                    }
                }).catch(() => {
                    return({
                        message_message: 'We cannot process your request now. Please try again later'
                    });
                });

                return request;
            } catch (err) {
                throw new Error(err);
            }
        } else {
            setErrors(validateErrors);
        }

        return handleRegistrationSubmit;
    };

    const handleAccountSubmit = async e => {
        e.preventDefault();

        const formToValidate = updateForm;
        const validateErrors = validateForm(formToValidate, isAccountForm);

        const { fullName, dob } = validateErrors;

        if (fullName === null && dob === null) {
            try {
                const dobIn = moment(`${formToValidate.dobYear}-${formToValidate.dobMonth}-${formToValidate.dobDay}`, "YYYY-MM-DD");

                const requestData = {
                    consumer_name: formToValidate.fullName,
                    preference_next_year: formToValidate.favouriteCuisine,
                    preference_postcode: formToValidate.postcode,
                    preference_first_experience: formToValidate.firstTasteExperience,
                    consumer_dob: dobIn.isValid() ? dobIn.format("YYYY-MM-DD") : null,
                    consumer_marketing_consent: !!formToValidate.newsletterSignup,
                };

               const request = await fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/consumer/${formToValidate.userId}`, {
                    method: "PATCH",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Database.getUserToken()}`,
                    },
                    body: JSON.stringify(requestData),
                }).then(async response => {
                    const responseObject = await response.json();
                    const status = response.status;

                    if (!responseObject) {
                        throw new Error("failed while trying to get response");
                    }

                    if (status === 200 || status === 201) {
                        return({
                            success_message: "Your profile was successfully updated."
                        }) 
                    } else {
                        return responseObject
                    }
                }).catch(() => {
                    return({
                        message_message: 'We cannot process your request now. Please try again later.'
                    });
                });

                return request
            } catch (err) {
                throw new Error(err);
            }
        } else {
            setErrors(validateErrors);
        }
    };

    return {
        handleChange,
        handleSubmit: isAccountForm ? handleAccountSubmit : handleRegistrationSubmit,
        form,
        errors,
        updateForm,
        setForm,
        setUpdateForm,
    };
};

export default useForm;
